<template>
  <div>
    <div class="test__pager container-grid">
      <a href="javascript:void(0)" v-for="(question, key) in test.questions" :key="key" @click="setCurrent(key)" class="container-grid__item test__pager__item" :class="{
        'test__pager__item-current': currentKey === key,
        'test__pager__item-complete': checkIsComplete(question),
        'text-success': question.user && question.user.correct,
        'text-danger': question.user && typeof question.user.correct !== 'undefined' &&!question.user.correct
      }">
        {{ key + 1 }}
      </a>
    </div>
    <div v-for="(question, key) in test.questions" :key="key" class="test__item">
      <template v-if="currentKey === key">
        <div class="test__item-title mb-2" v-html="question.title"></div>
        <div class="form-check test__item-question">
          <template v-if="!question.multiple && !question.typed && model[question.id]">
            <div class="test__item-question-item form-radio radio" v-for="(answer, indexKey) in question.answers" :key="indexKey" :class="{correct: answer.correct && answer.correct === true, incorrect: answer.correct === false}">
              <input :disabled="$parent.isFinished" type="radio" :name="question.id" @change="sendQueue()" :value="answer.id" :id="key+'_'+indexKey" v-model="model[question.id].answer" />
              <label :for="key+'_'+indexKey" v-html="answer.title"></label>
            </div>
          </template>
          <template v-else-if="question.multiple">
            <div class="test__item-question-item form-checkbox" v-for="(answer, indexKey) in question.answers" :key="indexKey" :class="{correct: answer.correct && answer.correct === true, incorrect: answer.correct === false}">
              <div class="form-checkbox__wrapper">
                <input :disabled="$parent.isFinished" type="checkbox" @change="sendQueue()" :value="answer.id" :id="key+'_'+indexKey" v-model="model[question.id].answer[answer.id]" />
                <label class="d-inline-block" :for="key+'_'+indexKey" v-html="answer.title"></label>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: "TestQuestionList",
  data() {
    return {
      model: {}
    }
  },
  props: {
    value: {
      type: Object,
      default() {
        return null
      }
    },
    test: {
      type: Object,
      default() {
        return {}
      }
    },
    currentKey: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  mounted() {
    this.model = this.value
  },
  methods: {
    sendQueue() {
      eventBus.$emit('test:sendQueue')
    },
    setCurrent(key) {
      eventBus.$emit('test:setCurrentKey', (key))
    },
    checkIsComplete(question) {

      if(!this.model[question.id]) {
        return;
      }

      if(!question.multiple) {
        return this.model[question.id].answer
      }

      if(this.model[question.id] && this.model[question.id].answer) {
        return Object.keys(this.model[question.id].answer).filter(key => key !== "").length
      }

      return false;

    }
  },
  watch: {
    model: {
      handler: function(val) {
        this.$emit('input', val)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>