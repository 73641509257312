<template>
  <div v-if="test" class="d-flex justify-content-between mb-24 text-blue-03">
    <div>Завершено на {{ (100 * answeredLength/test.questionsCount).toFixed(0) }}%</div>
    <vue-countdown  :time="getTime()" :transform="transform">
      <template slot-scope="props">
        <div class="card__duration">{{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}</div>
      </template>
    </vue-countdown>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import moment from "moment/moment";
export default {
  name: "TestStartProgress",
  components: {VueCountdown},
  props: {
    test: {
      type: Object,
      default() {
        return null
      }
    },
    answeredLength: {
      type: Number,
      default() {
        return 0
      }
    },
  },
  methods: {
    getTime() {
      const end  = moment(this.test.start).add(this.test.duration, 'minutes')
      const start = moment()
      return end - start
    },
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        const digits = value < 10 ? `0${value}` : value;
        props[key] = `${digits}`;
      });

      return props;
    },
  }
}
</script>

<style scoped>

</style>